<template>
  <div class=" d-flex  flex-column">
    <PaymentHeader :payment1="payment1" :payment2="payment2" />
    <div class="payment-content d-flex px-5 mt-5">
    <div>
      <payment-method />
    </div>
    <div>
      <div class="d-flex flex-column" v-if="selectedPaymentMethodType.name === 'Split Payment'">
        <div class="tab d-flex mb-4">
          <button class="tab--item font-700 font-nunito bg-transparent" :class="{ 'active': sectionPayment == 1 }"
            @click="sectionPayment = 1">Pembayaran 1</button>
          <button class="tab--item font-700 font-nunito bg-transparent" :class="{ 'active': sectionPayment == 2 }"
            @click="sectionPayment = 2">Pembayaran 2</button>

        </div>
        <div class="d-flex flex-column" v-if="sectionPayment === 1">
          <div class="d-flex flex-column gap-2">
            <span class="font-roboto">Pembayaran </span>
            <el-select v-model="payment1.selectedPaymentMethodTypeID" placeholder="Pilih Pembayaran" @change="handleSelectPaymentMethodType1()">
              <el-option v-for="payment in tunaiOnlyPaymentMethods" :key="payment.id" :label="payment.name"
                :value="payment.id">
              </el-option>
            </el-select>
          </div>
          <template v-if="payment1.selectedPaymentMethodTypeID">
            <payment-account :payment-methods="payment1.selectedPaymentMethodType.payment_methods" :selectedPaymentMethodId="payment1.id" 
            v-if="payment1.selectedPaymentMethodType.name.toLowerCase() === 'edc' || payment1.selectedPaymentMethodType.name.toLowerCase() === 'bank transfer' || payment1.selectedPaymentMethodType.name.toLowerCase() === 'transfer bank'" />
            <image-input class="border-top pt-4 mt-4" ref="imageInput" @uploadImage="(image) => {payment1.payment_proof_image = image}"
              v-if="payment1.selectedPaymentMethodType.payment_proof === 'required' || payment1.selectedPaymentMethodType.payment_proof === 'optional'" />
          </template>
          <payment-input class="mt-3" :selectedPaymentMethodType="payment1.selectedPaymentMethodType" @padClicked="(value) => {
            handlePayment1Input(value)
          }" :inputData="payment1.totalPayment" :show-return="false" />

        </div>
        <div class="d-flex flex-column" v-if="sectionPayment === 2">
          <div class="d-flex flex-column gap-2">
            <span class="font-roboto">Pembayaran </span>
            <el-select v-model="payment2.selectedPaymentMethodTypeID" placeholder="Pilih Pembayaran" @change="handleSelectPaymentMethodType2()">
              <el-option v-for="payment in withoutTunaiPaymentMethods" :key="payment.id" :label="payment.name"
                :value="payment.id">
              </el-option>
            </el-select>
          </div>
          <div class="payment-preview mt-4">
            <span class="font-roboto">Total Pembayaran </span>

            <el-input class="mt-2" :value="formatRupiah(payment2.totalPayment, 'Rp. ')" disabled />

          </div>
          <template v-if="payment2.selectedPaymentMethodTypeID">
            <payment-account :payment-methods="payment2.selectedPaymentMethodType.payment_methods" :selectedPaymentMethodId="payment2.id" @setPaymentMethod="(method) => { setMethod2(method)}" 
            v-if="payment2.selectedPaymentMethodType.name.toLowerCase() === 'edc' || payment2.selectedPaymentMethodType.name.toLowerCase() === 'bank transfer' || payment2.selectedPaymentMethodType.name.toLowerCase() === 'transfer bank'" />
            <image-input class="border-top pt-4 mt-4" ref="imageInput" @uploadImage="(image) => {payment2.payment_proof_image = image}"
              v-if="payment2.selectedPaymentMethodType.payment_proof === 'required' || payment2.selectedPaymentMethodType.payment_proof === 'optional'" />
          </template>
          <payment-input class="mt-3" :selectedPaymentMethodType="payment2.selectedPaymentMethodType" @padClicked="(value) => {
            payment2.account_number = value
          }" :inputData="payment2.account_number" :show-return="false" />

        </div>

      </div>
      <payment-input :selectedPaymentMethodType="selectedPaymentMethodType" @padClicked="(value) => {
       SET_INPUT_DATA(value)
      }" :inputData="inputData" />
    </div>
  </div>
  </div>

</template>

<script>

import IhsMixin from "@/mixins/ihs.mixins.js";

import PaymentInput from '@/components/payment/partials/PaymentInput.vue'
import PaymentMethod from '@/components/payment/partials/PaymentMethod.vue'
import ImageInput from '@/components/base/ImageInput.vue'
import PaymentHeader from '@/components/payment/partials/PaymentHeader.vue';

import PaymentAccount from '@/components/payment/partials/PaymentAccount.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  mixins: [IhsMixin],
  components: { PaymentMethod, PaymentInput, ImageInput, PaymentAccount, PaymentHeader},
  data() {
    return {
      sectionPayment: 1,
      payment1: {
        id: '',
        totalPayment: 0,
        selectedPaymentMethodTypeID: '',
        payment_proof_image: '',
        selectedPaymentMethodType: {
          id: '',
          name: ''
        }
      },
      payment2: {
        id: '',
        totalPayment: 0,
        account_number: '',
        selectedPaymentMethodTypeID: '',
        payment_proof_image: '',
        selectedPaymentMethodType: {
          id: '',
          name: ''
        }
      }
    }
  },
  computed: {
    ...mapState('payment', ['selectedPaymentMethodType', 'inputData', 'usePoint', 'paymentMethods']),
    ...mapState('user', ['calculatedPoint']),
    ...mapGetters('cart', ['finalPrice']),
  
    sortedPaymentMethods() {
      const sortingOrder = ['Tunai', 'EDC', 'Transfer Bank', 'QRIS'];
      const sortedMethods = [...this.paymentMethods];

      sortedMethods.sort((a, b) => {
        const indexA = sortingOrder.indexOf(a.name);
        const indexB = sortingOrder.indexOf(b.name);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });

      return sortedMethods;
    },
    tunaiOnlyPaymentMethods() {
      return this.paymentMethods.filter(payment => payment.name.toLowerCase() === 'tunai')
    },
    withoutTunaiPaymentMethods() {
      return this.paymentMethods.filter(payment => payment.name.toLowerCase() !== 'tunai')
    }

  },

  watch: {
    'payment1.totalPayment'(value) {
      this.payment2.totalPayment = (this.usePoint ? this.finalPrice - this.calculatedPoint : this.finalPrice ) - this.unformatRupiah(value)
    }
  },
  methods: {
    ...mapMutations('payment', ['SET_INPUT_DATA']),
    setMethod2(method) {
     this.payment2.id = method.id
    },
    handleSelectPaymentMethodType1() {
      const method = this.paymentMethods.find(payment => payment.id === this.payment1.selectedPaymentMethodTypeID)
      this.payment1.id = method.payment_methods[0].id
      this.payment1.selectedPaymentMethodType = method
    },
    handleSelectPaymentMethodType2() {
      const method = this.paymentMethods.find(payment => payment.id === this.payment2.selectedPaymentMethodTypeID)
      this.payment2.id = method.payment_methods[0].id
      this.payment2.selectedPaymentMethodType = method
    },
    getImage(img) {
      this.SET_SELECTED_PAYMENT_IMAGE_PROOF(img)
    },
    handlePayment1Input(value) {
      this.payment1.totalPayment = value
      this.SET_INPUT_DATA(value)
    }

  },
  mounted() {
this.SET_INPUT_DATA('0') 
 }
}
</script>

<style lang="scss" scoped>
.payment-content {
  width: 862px;
  margin: auto;
  min-height: 500px;
  gap: 100px;
}

.tab {
  width: 100%;
  border-bottom: 1px solid #CDCDCE;
  gap: 25px;
  .tab--item {
    border: 0;
    font-size: 18px;
    padding-bottom: 7px;
    border-bottom: 5px solid transparent;
    word-break: keep-all;
    &.active {
      border-bottom: 5px solid #00B962;
      color: #00B962;
    }
  }
}

</style>

<style lang="scss">
.payment-preview {

    .el-input__inner {
        padding: 18px 12px;
        border: 1px solid #DCDFE6 !important;
        font-size: 16px;

        &::placeholder {
            color: black;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: black;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: black;
        }
    }
 }
</style>